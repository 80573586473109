import { useCallback, useState } from 'react';
import { Theme } from '../../Types';
import klydoApiClient from '../../api/klydo';
import uploadFile from '../../utils';

const useCreateKlydo = () => {
  const [privacyState, setPrivacyState] = useState('Public');
  const [klydoTitle, setKlydoTitle] = useState('');
  const [klydoUrl, setKlydoUrl] = useState(
    'https://res.cloudinary.com/klydoclock/v1670851782/dev/hrt9dqaf1ecjllfn30g8.gif',
  );
  const [suggestedColors, setSuggestedColors] = useState<Theme[]>([]);
  const [selectedTheme, setSelectedTheme] = useState<Theme>({
    backgroundColor: '#ffffff',
    handsColor: '#000000',
    pendulumColor: '#ffffff',
    pendulumRodColor: '#000000',
    dialsColor: undefined,
  });
  //TODO: Implement dials
  //   const [isDialsOn, setIsDialsOn] = useState(false);
  //   const [shouldOpenCropModal, setShouldOpenCropModal] = useState(false);
  //   const [cropKlydo, setCropKlydo] = useState({ x: 0, y: 0 });
  //   const [zoomKlydo, setZoomKlydo] = useState(1);

  const handleFileUpload = async (fileUrl: string) => {
    // setUploadingGif(true);
    uploadFile({
      file: fileUrl,
      folder: 'gifs',
      upload_preset: 'Bezalel_preset',
      onUploadProgress: (progress) => {
        // TODO: Implement progress bar (?)
        console.log('Upload progress:', progress);
        // setUploadProgress(progress);
      },
    })
      .then((response) => {
        // setMemoryUsage(getUploadResDetails(response));
        console.log('Upload response:', response);
        setKlydoUrl(response.secure_url);
        klydoApiClient
          .processImage(response.secure_url)
          .then((res) => {
            console.log('Themes', res);
            setSuggestedColors(res);
            setSelectedTheme(res[0]);
          })
          .catch((error) => {
            console.error('Process image error:', error);
          });
      })
      .catch((error) => {
        console.error('Upload error:', error);
        // setWarning("Upload failed!");
      })
      .finally(() => {
        // setUploadingGif(false);
      });
  };

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      console.log('File', file);
      if (!file) return;
      const validTypes = ['video/mp4', 'image/gif'];
      if (!validTypes.includes(file.type)) {
        alert('Invalid file type. Only MP4 and GIF files are allowed.');
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        console.log('File loaded', reader.result);
        setKlydoUrl(reader.result as string);
        // TODO: file upload should happen after cropping
        handleFileUpload(reader.result as string);
        // klydoApiClient.processImage(reader.result as string).then((res) => {
        //   setSuggestedColors(res);
        //   console.log('Themes', res);
        // });
      };
      reader.readAsDataURL(file);
    },
    [],
  );
  const handleCreateKlydo = useCallback(() => {
    console.log('Create Klydo');
  }, []);

  return {
    privacyState,
    setPrivacyState,
    handleCreateKlydo,
    klydoTitle,
    setKlydoTitle,
    handleFileChange,
    klydoUrl,
    suggestedColors,
    selectedTheme,
    setSelectedTheme,
    // shouldOpenCropModal,
    // cropKlydo,
    // setCropKlydo,
    // zoomKlydo,
    // setZoomKlydo,
  };
};

export default useCreateKlydo;
