import { useEffect, useRef } from 'react';
import { useOnScreen } from '../../../hooks/useOnScreen';
import { parseCloudinaryUrl } from '../../../utils';
import { Klydo } from '../../../Types';
import { keyframes } from '@emotion/react';

const secondsForMin = 3600;
const secondsForHour = 43200;

const useKlydoPreview = (klydo: Klydo) => {
  const ref = useRef<HTMLDivElement>(null);
  const handMin = useRef<HTMLDivElement>(null);
  const handHour = useRef<HTMLDivElement>(null);
  const handHourS = useRef<HTMLDivElement>(null);
  const handMinS = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const ii = useRef<NodeJS.Timeout>();
  const loopUrl = parseCloudinaryUrl({
    url: klydo?.convertedLoopUrl || klydo?.loopUrl,
    isStatic: false,
    size: 200,
  });
  const handsColor = klydo?.theme?.handsColor;

  useEffect(() => {
    const updHands = () => {
      const dt = new Date();
      const seconds = dt.getMinutes() * 60 + dt.getSeconds();
      const dgmin = (seconds / secondsForMin) * 360;
      if (handMin.current) {
        handMin.current.style.transform = `rotate(${dgmin}deg)`;
      }
      if (handMinS.current) {
        handMinS.current.style.transform = `rotate(${dgmin}deg)`;
      }
      const hours = (dt.getHours() % 12) * 3600 + seconds;
      const dgh = (hours / secondsForHour) * 360;
      if (handHour.current) {
        handHour.current.style.transform = `rotate(${dgh}deg)`;
      }
      if (handHourS.current) {
        handHourS.current.style.transform = `rotate(${dgh}deg)`;
      }
    };
    ii.current = setInterval(updHands, 2000);
    updHands();
    return () => clearInterval(ii.current);
  }, [isVisible]);
  const swingAnimation = keyframes`
  0% { transform: rotate(-20deg); }
  50% { transform: rotate(20deg); }
  100% { transform: rotate(-20deg); }
`;
  return {
    ref,
    handMin,
    handHour,
    handHourS,
    handMinS,
    isVisible,
    loopUrl,
    handsColor,
    backgroundColor: klydo?.theme?.backgroundColor,
    pendulumColor: klydo?.theme?.pendulumColor,
    pendulumRodColor: klydo?.theme?.pendulumRodColor,
    swingAnimation,
  };
};
export default useKlydoPreview;
