const firebaseConfig = {
  apiKey: 'AIzaSyCnNaft6lR7g9RWBeofSQVRiefqrPZWimg',
  authDomain: 'dazzling-mantra-347515.firebaseapp.com',
  projectId: 'dazzling-mantra-347515',
  storageBucket: 'dazzling-mantra-347515.appspot.com',
  messagingSenderId: '917257785826',
  appId: '1:917257785826:web:4dcd5036984c3f8448a9bb',
  measurementId: 'G-L4DBBBVNFT',
};

export default firebaseConfig;
