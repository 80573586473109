// import { isIOS, isMobile } from 'react-device-detect';
import DSBox from '../../design-system/DSBox';
import { Klydo } from '../../Types';
import useKlydoPreview from './hooks/useKlydoPreview';
import KlydoLoopUrl from './KlydoLoopUrl';

const KlydoPreview = (klydo: Klydo): JSX.Element => {
  const {
    ref,
    backgroundColor,
    loopUrl,
    handsColor,
    pendulumColor,
    pendulumRodColor,
    handHour,
    handMin,
    handHourS,
    handMinS,
    swingAnimation,
  } = useKlydoPreview(klydo);

  return (
    <div ref={ref}>
      <DSBox
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          display: 'flex',
          width: '100%',
        }}
      >
        {!!loopUrl && (
          <DSBox
            sx={{
              backgroundColor: backgroundColor,
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'center',
              display: 'flex',
              width: '70%',
            }}
          >
            <KlydoLoopUrl loopUrl={loopUrl} />
            <time
              dateTime="2023-06-30T04:48:07.668Z"
              style={{
                padding: '38.5%',
                position: 'absolute',
              }}
            >
              <div // hour shadow
                ref={handHourS}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  left: '0.4%',
                  top: '1.2%',
                  transformOrigin: 'center',
                  zIndex: 2,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    borderRadius: '10px',
                    width: '4%',
                    height: '35%',
                    bottom: '46%',
                    left: '48%',
                    background:
                      'radial-gradient(ellipse at center, #000000 0%, #00000000 100%)',
                  }}
                />
              </div>
              <div // hour
                ref={handHour}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  transformOrigin: 'center',
                  zIndex: 3,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    borderRadius: '10px',
                    width: '4%',
                    height: '35%',
                    bottom: '46%',
                    left: '48%',
                    backgroundColor: handsColor,
                  }}
                />
              </div>
              <div // minute shadow
                ref={handMinS}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  left: '1.2%',
                  top: '1.4%',
                  transformOrigin: 'center',
                  zIndex: 2,
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    borderRadius: '4px',
                    width: '3%',
                    height: '45%',
                    bottom: '45%',
                    left: '48.75%',
                    background:
                      'radial-gradient(ellipse at center, #000000 0%, #00000000 100%)',
                  }}
                />
              </div>
              <div // minute
                ref={handMin}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  zIndex: 3,
                  transformOrigin: 'center',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    borderRadius: '4px',
                    width: '3%',
                    height: '45%',
                    bottom: '45%',
                    left: '48.75%',
                    backgroundColor: handsColor,
                  }}
                />
              </div>
            </time>
          </DSBox>
        )}
        <DSBox
          sx={{
            position: 'absolute',
            bottom: '25%',
            height: '20%',
            width: '33%',
            borderRadius: '50%',
            displayPrint: 'flex',
            backgroundColor: backgroundColor,
            overflow: 'hidden',
          }}
        >
          <DSBox
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              transformOrigin: ' 50% 0px',
              animation: `${swingAnimation} 2s ease-in-out infinite`,
            }}
          >
            <DSBox
              sx={{
                backgroundColor: pendulumRodColor,
                width: '4%',
                height: '12%',
              }}
            />
            <DSBox
              borderRadius="50%"
              boxShadow={'rgba(0, 0, 0, 0.55) 0px 2px 3.2px 0px'}
              sx={{
                width: '80%',
                height: '80%',
                backgroundColor: pendulumColor,
              }}
            />
          </DSBox>
        </DSBox>
      </DSBox>
    </div>
  );
};
export default KlydoPreview;
