import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Input,
  Paper,
} from '@mui/material';
import KlydoPreview from '../components/klydoPreview/KlydoPreview';
import React from 'react';
import DSButton from '../design-system/DSButtom';
import DSTextField from '../design-system/DSTextField';
import useCreateKlydo from './hooks/useCreateKlydo';
import SuggestedColorsButton from '../components/SuggestedColorsButton';

const CreateKlydo = () => {
  const {
    privacyState,
    setPrivacyState,
    handleCreateKlydo,
    klydoTitle,
    setKlydoTitle,
    handleFileChange,
    klydoUrl,
    suggestedColors,
    selectedTheme,
    setSelectedTheme,
  } = useCreateKlydo();
  const handleCKlydoThemeChange = (value: string) => {
    const valueInt = parseInt(value);
    setSelectedTheme(suggestedColors[valueInt]);
  };

  return (
    <Paper
      sx={{
        justifyItems: 'center',
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ marginTop: '20px', marginBottom: '10px' }}>
        <DSTextField
          placeholder="Klydo Title"
          value={klydoTitle}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setKlydoTitle(event.target.value);
          }}
        />
      </Box>
      <Box
        sx={{
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'stretch',
        }}
      >
        <SuggestedColorsButton
          theme={suggestedColors}
          onChangeColor={handleCKlydoThemeChange}
        />
        <div
          style={{
            width: '230px',
            height: '380px',
            position: 'relative',
          }}
        >
          <KlydoPreview
            name={''}
            loopUrl={klydoUrl}
            convertedLoopUrl={''}
            theme={selectedTheme}
            id={''}
            userId={''}
            public={false}
          />
        </div>

        <FormControl sx={{ color: 'white' }}>
          <RadioGroup
            value={privacyState}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPrivacyState(event.target.value);
            }}
          >
            <FormControlLabel
              value={'Public'}
              control={<Radio />}
              label="Public"
            />
            <FormControlLabel
              value={'Unlisted'}
              control={<Radio />}
              label="Unlisted"
            />
          </RadioGroup>
        </FormControl>
        {/* <Modal open={shouldOpenCropModal}>
          <Box>
            <Cropper
              video={klydoUrl}
              crop={cropKlydo}
              zoom={zoomKlydo}
              onZoomChange={setZoomKlydo}
              onCropChange={setCropKlydo}
              cropShape="round"
              aspect={1}
              //   onCropComplete={()}
            />
          </Box>
        </Modal> */}
      </Box>
      <Input
        inputProps={{ accept: '.mp4, .gif' }}
        type="file"
        onChange={handleFileChange}
        placeholder="Upload Klydo"
        sx={{
          top: 7 + '%',
          left: 11.5 + '%',
          width: 77 + '%',
          height: 47 + '%',
          opacity: 0,
        }}
      />
      <DSButton onClick={handleCreateKlydo}> Save Klydo </DSButton>
    </Paper>
  );
};

export default CreateKlydo;
