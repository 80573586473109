import { Theme } from '../Types';
import { baseClient } from './baseRequest';

export type KlydoApiClient = {
  processImage: (loopUrl: string) => Promise<Theme[]>;
  checkContrast: (filePath: string, handColor: string) => Promise<string>;
};

export type KlydoSearchFilter = { excludeIds: string[] };

const klydoApiClient: KlydoApiClient = {
  processImage: async (loopUrl: string) => {
    return baseClient.post({
      url: `/klydos/process-image`,
      body: {
        loopUrl: loopUrl,
      },
    });
  },

  checkContrast: async (filePath: string, handColor: string) => {
    return baseClient.post<string>({
      url: `/klydos/check-contrast`,
      body: {
        filePath: filePath,
        handColor: handColor,
      },
    });
  },
};

export default klydoApiClient;
