import { useState } from 'react';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from 'react-router-dom';
import logo from '../../../images/klydo-yellow.png';
import firebaseService from '../../../firebase/firebaseService';
import { KLYDO_BLACK, KLYDO_WHITE, KLYDO_YELLOW } from '../../../consts/colors';
import DSBox from '../../../design-system/DSBox';
import DSIconButton from '../../../design-system/DSIconButton';
import DSTypography from '../../../design-system/DSTypography';
import DSTextField from '../../../design-system/DSTextField';
import DSDivider from '../../../design-system/DSDivider';
import DSTabs from '../../../design-system/DSTabs';
import DSTab from '../../../design-system/DSTab';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';

const AuthPage = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();

  const validatePassword = (password: string) => {
    setPassword(password);
    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters.');
    } else if (!/(?=.*[a-z])(?=.*[A-Z])/.test(password)) {
      setPasswordError(
        'Password must include uppercase and lowercase letters.',
      );
    } else if (!/(?=.*\d)/.test(password)) {
      setPasswordError('Password must include at least one number.');
    } else if (!/(?=.*[@$!%*?&])/.test(password)) {
      setPasswordError('Password must include at least one special character.');
    } else {
      setPasswordError('');
    }
  };

  const handlePrimaryButtonPress = async () => {
    try {
      if (isRegister) {
        if (passwordError) {
          alert(
            'Your password must meet the specified requirements before proceeding.',
          );
          return;
        }
        await firebaseService.createUserWithEmailAndPassword(email, password);
      } else {
        await firebaseService.signInWithEmailAndPassword(email, password);
      }
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
    }
  };

  const handleForgotPassword = () => {
    if (!email) {
      alert('Please enter your email address first.');
      return;
    }
    firebaseService.sendPasswordResetEmail(email);
  };

  const handleGoogleSignIn = async () => {
    try {
      await firebaseService.signInWithGoogle();
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
    }
  };

  const handleTabChange = (
    _: React.ChangeEvent<unknown>,
    newValue: boolean,
  ) => {
    setIsRegister(newValue);
  };

  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: KLYDO_BLACK,
        color: KLYDO_WHITE,
        gap: 2,
        p: 3,
      }}
    >
      <DSBox sx={{ textAlign: 'center', mb: 3 }}>
        <img src={logo} alt="Klydo Clock Logo" style={{ width: 200 }} />
      </DSBox>

      <DSTabs
        value={isRegister}
        onChange={handleTabChange}
        sx={{
          marginBottom: 2,
          '.MuiTabs-flexContainer': {
            borderBottom: '1px solid #555',
          },
          '.MuiTab-root': {
            color: '#888',
            '&.Mui-selected': {
              color: KLYDO_YELLOW,
              fontWeight: 'bold',
            },
          },
        }}
      >
        <DSTab label="Login" value={false} />
        <DSTab label="Register" value={true} />
      </DSTabs>

      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: '100%',
          maxWidth: 400,
        }}
      >
        <DSTextField
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            style: { color: '#fff' },
          }}
          InputLabelProps={{
            style: { color: '#888' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#555' },
              '&:hover fieldset': { borderColor: KLYDO_YELLOW },
              '&.Mui-focused fieldset': { borderColor: KLYDO_YELLOW },
            },
          }}
        />

        <DSTextField
          label="Password"
          variant="outlined"
          type="password"
          fullWidth
          value={password}
          onChange={(e) => validatePassword(e.target.value)}
          InputProps={{
            style: { color: '#fff' },
          }}
          InputLabelProps={{
            style: { color: '#888' },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#555' },
              '&:hover fieldset': { borderColor: '#FFD700' },
              '&.Mui-focused fieldset': { borderColor: '#FFD700' },
            },
          }}
        />

        {passwordError && isRegister && password && (
          <DSTypography sx={{ color: 'red', fontSize: '0.875rem' }}>
            {passwordError}
          </DSTypography>
        )}

        {!isRegister && (
          <DSTypography
            onClick={handleForgotPassword}
            sx={{
              color: KLYDO_YELLOW,
              textAlign: 'right',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            Forgot Password?
          </DSTypography>
        )}
      </DSBox>

      <DSPrimaryButton onClick={handlePrimaryButtonPress}>
        {isRegister ? 'Join for Free' : 'Login'}
      </DSPrimaryButton>

      <DSDivider sx={{ my: 2, width: '100%', maxWidth: 400 }}>
        <DSTypography sx={{ color: KLYDO_WHITE }}>
          or continue with
        </DSTypography>
      </DSDivider>

      <DSIconButton
        onClick={handleGoogleSignIn}
        sx={{
          backgroundColor: '#fff',
          color: '#000',
          '&:hover': { backgroundColor: '#f5f5f5' },
        }}
      >
        <GoogleIcon />
      </DSIconButton>
    </DSBox>
  );
};

export default AuthPage;
