import React from 'react';
import { Button } from '@mui/material';

const DSPrimaryButton = (props: React.ComponentProps<typeof Button>) => {
  return (
    <Button
      color={'primary'}
      variant="contained"
      {...props}
      sx={{
        ...props.sx,
        borderRadius: 50,
        padding: '7px 25px',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: '#FFDF6A',
          transform: 'scale(1.03)',
        },
      }}
    />
  );
};

export default DSPrimaryButton;
