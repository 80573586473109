import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AuthPage from './views/SignUp/components/AuthPage';
import Dashboard from './views/Dashboard';
import CreateKlydo from './views/CreateKlydo';
import { createContext, ReactNode, useEffect, useState } from 'react';
import firebaseService from './firebase/firebaseService';
import firebase from 'firebase/compat/app';

interface AuthContextType {
  user: firebase.User | null;
  setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    const unsubscribe: firebase.Unsubscribe =
      firebaseService.onAuthStateChanged((authUser: firebase.User | null) => {
        setUser(authUser);
      });
    return () => unsubscribe();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthPage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/create/klydo" element={<CreateKlydo />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
