import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Theme } from '../Types';

type SuggestedColorsButtonProps = {
  theme?: Theme[];
  onChangeColor: (value: string) => void;
};

const SuggestedColorsButton = ({
  theme,
  onChangeColor,
}: SuggestedColorsButtonProps) => {
  return (
    <Box>
      {!!theme && (
        <FormControl>
          <RadioGroup
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onChangeColor(event.target.value);
            }}
          >
            {theme.map((colorOption: Theme, index: number) => (
              <FormControlLabel
                value={index}
                control={<Radio />}
                label={index}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    </Box>
  );
};
export default SuggestedColorsButton;
