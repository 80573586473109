import axios from 'axios';

export type ParseUrlInput = {
  url: string;
  isStatic?: boolean;
  size?: number;
};

export const parseCloudinaryUrl = (input: ParseUrlInput) => {
  let { url } = input;
  if (!url) return;
  const { isStatic, size } = input;
  const type = url.split('.').pop();
  const params = `c_scale,w_${size}`;
  const webpConversionParams = 'fl_animated,fl_awebp,e_loop';
  const framesParams = 'so_auto';
  if (type === 'mp4') {
    if (isStatic) {
      url = url.replace('mp4', 'jpg');
      if (url!.includes('image/upload') || url.includes('video/upload')) {
        url = url.replace('/upload/', `/upload/${framesParams}/`);
      } else {
        url = url.replace('/klydoclock/', `/klydoclock/${framesParams}/`);
      }
    }
    if (size) {
      let sizeParams = params;
      if (type === 'mp4' && !isStatic) {
        url = url.replace('mp4', 'webp');
        sizeParams = `${params}/${webpConversionParams}`;
      }
      if (url!.includes('image/upload') || url.includes('video/upload')) {
        url = url.replace('/upload/', `/upload/${sizeParams}/`);
      } else {
        url = url.replace('/klydoclock/', `/klydoclock/${sizeParams}/`);
      }
    }
  }
  return url;
};

type ViewType = 'video' | 'image' | 'none';
const imgTypes = [
  'jpg',
  'gif',
  'avif',
  'apng',
  'png',
  'ico',
  'cur',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'tif',
  'webp',
  'bmp',
];
const videoTypes = ['mp4', 'mpge', 'avi', 'mov', 'mkv', 'webm', 'ogg', 'ogv'];
export function isVideo(url: string): ViewType {
  if (url.startsWith('data'))
    return url.startsWith('data:video')
      ? 'video'
      : url.startsWith('data:image')
        ? 'image'
        : 'none';
  const arr = url.split('.');
  const typ = arr[arr.length - 1];
  return imgTypes.includes(typ)
    ? 'image'
    : videoTypes.includes(typ)
      ? 'video'
      : 'none';
}

export type UploadFileInput = {
  file: File | string;
  folder: string;
  upload_preset?: string;
  onUploadProgress?: (progress: number) => void;
};

const uploadFile = async (input: UploadFileInput) => {
  try {
    const response = await axios.post(
      'https://api.cloudinary.com/v1_1/KlydoClock/auto/upload',
      {
        file: input.file,
        folder: input.folder,
        upload_preset: input.upload_preset || 'Bezalel_preset',
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        //   if (input.onUploadProgress) {
        //     const percentCompleted = Math.floor(
        //       (progressEvent.loaded * 100) / progressEvent.total,
        //     );
        //     input.onUploadProgress(percentCompleted);
        //   }
        // },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Upload error:', error);
    throw new Error('Upload failed');
  }
};

export default uploadFile;
